import React from "react";
import NavItem from "./item";
import { useAuthBasics } from "../../hooks/useAuthBasics";
import OutButtonNav from "./out";

const Sidebar = () => {
  const { routes } = useAuthBasics();
  return (
    <div
      id="sidebar"
      className="flex flex-col justify-between h-full min-w-[270px] max-w-[350px] w-[18vw] select-none bg-grey"
    >
      <div className="flex flex-col">
        {routes
          .filter((e) => e.showInSidebar && !!!e.specify)
          .map((item, index) => {
            return <NavItem key={index} item={item} />;
          })}
      </div>
      <div className="flex flex-col pb-7">
        {routes
          .filter((e) => e.showInSidebar && e.specify === "sidebar-bottom")
          .map((item, index) => {
            return <NavItem key={index} item={item} />;
          })}
        <OutButtonNav />
      </div>
    </div>
  );
};

export default Sidebar;
