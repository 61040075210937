import React from "react";

const ValidationIcon = ({ color = "white" }) => {
  return (
    <svg
      width="69"
      height="69"
      viewBox="0 0 69 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.875 34.5L31.625 40.25L44.5625 27.3125M26.4555 59.2275C27.3977 59.1029 28.3496 59.3585 29.1002 59.9367L32.5595 62.5912C33.703 63.4696 35.2938 63.4696 36.4341 62.5912L40.0243 59.8344C40.6951 59.3201 41.5416 59.0933 42.3784 59.2051L46.8695 59.7961C48.2973 59.9846 49.6739 59.1892 50.2265 57.8571L51.9546 53.6789C52.2772 52.8963 52.8969 52.2766 53.6794 51.954L57.8574 50.2258C59.1894 49.6764 59.9847 48.2964 59.7963 46.8685L59.2277 42.5402C59.1032 41.5979 59.3587 40.6459 59.9368 39.8953L62.5912 36.4358C63.4696 35.2922 63.4696 33.7014 62.5912 32.561L59.8346 28.9706C59.3204 28.2998 59.0936 27.4533 59.2054 26.6163L59.7963 22.1251C59.9847 20.6972 59.1894 19.3204 57.8574 18.7678L53.6794 17.0397C52.8969 16.717 52.2772 16.0973 51.9546 15.3147L50.2265 11.1365C49.6771 9.80444 48.2973 9.00904 46.8695 9.19751L42.3784 9.78846C41.5416 9.90346 40.6951 9.67666 40.0275 9.16556L36.4373 6.40884C35.2938 5.53039 33.703 5.53039 32.5627 6.40884L28.9725 9.16556C28.3017 9.67666 27.4552 9.90346 26.6184 9.79485L22.1273 9.2039C20.6996 9.01543 19.3229 9.81083 18.7703 11.1429L17.0454 15.3211C16.7196 16.1005 16.0999 16.7202 15.3206 17.046L11.1426 18.771C9.8106 19.3236 9.01525 20.7004 9.2037 22.1283L9.79463 26.6195C9.90323 27.4564 9.67644 28.303 9.16537 28.9706L6.4088 32.561C5.5304 33.7046 5.5304 35.2954 6.4088 36.4358L9.16537 40.0262C9.67964 40.697 9.90642 41.5436 9.79463 42.3805L9.2037 46.8717C9.01525 48.2996 9.8106 49.6764 11.1426 50.229L15.3206 51.9572C16.1031 52.2798 16.7228 52.8995 17.0454 53.6821L18.7735 57.8603C19.3229 59.1924 20.7027 59.9878 22.1305 59.7993L26.4555 59.2275Z"
        stroke={color}
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ValidationIcon;
