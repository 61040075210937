import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getUserData } from "../contexts/auth/functions";
import { UserModel } from "../models/models";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "./useAuth";

export function useUser() {
  const { tokensDatas, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const {
    isLoading,
    data: result,
    ...args
  } = useQuery({
    queryKey: ["userdata"],
    queryFn: () => getUserData(),
    enabled: !!tokensDatas.token,
  });
  if (!isLoading && !!result?.tokenExpired) {
    queryClient.invalidateQueries(["tokens"]);
    setTimeout(() => {
      queryClient.invalidateQueries(["User"]);
    }, 200);
  }
  if (!isLoading && !!result?.logOut) {
    logout().then(() => {
      navigate("login", { state: { from: location }, replace: true });
    });
  }
  if (!isLoading && !!result?.success && !!tokensDatas.token) {
    const redirecto = !!location.state?.from?.pathname && location.state?.from;
    if (redirecto) {
      navigate(redirecto);
    }
  }
  return {
    isLoading,
    user: (result?.data as UserModel) ?? ({} as UserModel),
    ...args,
  };
}
