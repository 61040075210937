import React from "react";
import AddProject from "./addproject";
import Waitproject from "./waitproject";
import ValidatedProject from "./validatedproject";
import InformationBloc from "./informationbloc";
import HelpCard from "./help";

const DashWelcome = () => {
  return (
    <div className="flex h-auto flex-col gap-6">
      <span className="flex text-[19px] font-bold text-primary">
        Bienvenue sur le tableau de bord
      </span>
      <div className="flex flex-row gap-6">
        <div className="flex flex-1 flex-col gap-10">
          <div className="flex flex-row gap-4 w-full flex-wrap">
            <AddProject />
            <Waitproject />
            <ValidatedProject />
          </div>
          <div className="flex flex-col gap-5">
            <div className="flex flex-col rounded-xl bg-grey min-h-[400px] p-7 pt-5">
              <span className="flex text-[15px] text-primary">Récents projets</span>
            </div>
            <div className="flex flex-col rounded-xl bg-grey min-h-[400px] p-7 pt-5">
              <span className="flex text-[15px] text-primary">Dernière transaction</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-[300px] gap-5">
          <InformationBloc />
          <HelpCard />
        </div>
      </div>
    </div>
  );
};

export default DashWelcome;
