import React from 'react';
import './App.css';
import { Outlet, Route, Routes } from 'react-router-dom';
import LayoutPage from './pages/layout';
import Home from './pages/home';
import MissingPage from './pages/missing';
import PrivateRoute from './middlewares/protectedRoute';
import LoginPage from './pages/login';
import DashboardPage from './pages/dashboard';
import GenerateSubRoutes from './components/routes/generateSubRoutes';
import { AuthRequireRoutes } from './contexts/authbasics/defaults/subroutes';
import SignUpPage from './pages/signup';
import ForgotPasswordPage from './pages/forgotpasword';

function App() {
  return (
    <Routes>
      <Route path="/" element={<LayoutPage />}>
        <Route path="/">
          <Route element={<Home />}>
            <Route path="/" element={<div />} />
          </Route>
        </Route>
        <Route
          element={
            <PrivateRoute>
              <Outlet />
            </PrivateRoute>
          }
        >
          {RoutesRequiredAuth()}
        </Route>
        {RoutesWithoutAuth()}
        {/* Catch all */}
        <Route path="*" element={<MissingPage />} />
      </Route>
    </Routes>
  );
}

export function RoutesRequiredAuth() {
  return (
    <Route element={<Outlet />}>
      <Route path="dashboard" element={<DashboardPage />}>
        {GenerateSubRoutes(AuthRequireRoutes)}
      </Route>
    </Route>
  );
}

export function RoutesWithoutAuth() {
  return (
    <Route element={<Outlet />}>
      <Route path="login" element={<LoginPage />} />
      <Route path="signup" element={<SignUpPage />} />
      <Route path="forgotpassword" element={<ForgotPasswordPage />} />
    </Route>
  );
}

export default App;
