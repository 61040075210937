import React from "react";
import { useAuth } from "../../hooks/useAuth";
import LoadingPage from "../loading";
import { Navigate } from "react-router-dom";

function Home() {
  const { isAuthenticated, tokensDatasLoading } = useAuth();
  if (tokensDatasLoading) {
    return <LoadingPage message={"Preparation en cours.."} />;
  } else if (isAuthenticated) {
    return <Navigate to={"dashboard"} />;
  } else if (!isAuthenticated) {
    return <Navigate to={"login"} />;
  }
  return <div />;
}

export default Home;
