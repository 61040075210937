import React from "react";

const InformationBloc = () => {
  return (
    <div className="flex h-[450px] rounded-xl bg-tertiary text-white p-5 pt-3">
      <span className="flex text-[17px] font-medium">Informations importante</span>
    </div>
  );
};

export default InformationBloc;
