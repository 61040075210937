import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getTokens, setTokens } from "../contexts/auth/functions";
import React from "react";

export function useTokenManager() {
  const queryClient = useQueryClient();
  const {
    isLoading: isLoadingfirst,
    data: tokensDatas = { token: "", refreshToken: "" },
  } = useQuery({
    queryKey: ["tokens"],
    queryFn: () => getTokens(),
    // staleTime: 1000 * 3,
  });
  const [isLoading, setloading] = React.useState(isLoadingfirst);
  const tokensMutation = useMutation({
    mutationFn: setTokens,
    onSuccess: (c, tks, e) => {
      setTimeout(() => {
        queryClient.setQueryData(["tokens"], tks);
      }, 1500);
    },
  });
  async function updateTokens(tokens = { token: "", refreshToken: "" }) {
    tokensMutation.mutate(tokens);
  }
  React.useEffect(() => {
    setTimeout(() => {
      setloading(isLoadingfirst);
    }, 1000);
  }, [isLoadingfirst]);

  return { isLoading, tokensDatas, updateTokens };
}
