import axios from "axios";
import { decrypt } from "./encryption";
import { tokenRefac } from "./apiRequest";
import { setTokens } from "../contexts/auth/functions";

export const onlineApisUrl = "https://finalinvest-api.vercel.app";
export const localApisUrl = window.location.hostname.includes("local")
  ? "http://localhost:4010"
  : window.location.hostname.includes("192.168")
  ? "http://" + window.location.hostname + ":4010"
  : onlineApisUrl;

export const usedBaseUrl = localApisUrl;

export const api = axios.create({
  baseURL: usedBaseUrl + "/v1",
  params: {
    // key: process.env.REACT_APP_API_KEY,
  },
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const encrypted = error.response.data.encTxt;
    const result = decrypt(
      encrypted,
      process.env.REACT_APP_Key,
      process.env.REACT_APP_Iv
    );
    if (
      result.tokenExpired === true &&
      originalRequest._retry !== true &&
      error.response.status === 401
    ) {
      let tkns = tokenRefac({
        token: result.freshToken,
        refreshToken: result.freshRefreshToken,
      });
      originalRequest._retry = true;
      originalRequest.headers["Authorization"] = `Bearer ${tkns.token}`;
      originalRequest.headers[
        "x-refresh-token"
      ] = `Bearer ${tkns.refreshToken}`;
      await setTokens(tkns);
      return api(originalRequest).then((response) => {
        const result = decrypt(
          response.data.encTxt,
          process.env.REACT_APP_Key,
          process.env.REACT_APP_Iv
        );
        const resultEnc = decrypt(
          {
            ...result,
            tokenExpired: true,
          },
          process.env.REACT_APP_Key,
          process.env.REACT_APP_Iv
        );
        return {
          ...response,
          data: {
            ...response.data,
            encTxt: resultEnc,
          },
        };
      });
    }
  }
);
