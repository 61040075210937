import React from "react";

const PageDescription = ({ title = "", description = "" }) => {
  return (
    <div className="flex flex-col h-fit py-[13px] px-4 gap-1 rounded-[8px] w-fit bg-grey">
      {/* <span className="flex text-[13px] text-grey -mt-1">{title}</span> */}
      <span className="flex text-[14.5px] font-normal leading-snug text-darkgrey/90">{description}</span>
    </div>
  );
};

export default PageDescription;
