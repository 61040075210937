import React from "react";

const AddProject = () => {
  return (
    <div
      className="flex flex-col h-[230px] aspect-[7/5] rounded-xl bg-primary text-white p-6 justify-between gap-4 select-none"
    >
      <div className="flex flex-col gap-3 px-2">
        <span className="flex flex-col">
          <span className="flex text-[20px] font-semibold w-fit flex-wrap">
            Ajouter
          </span>
          <span className="flex text-[20px] font-semibold w-fit flex-wrap">
            un nouveau projet
          </span>
        </span>
        <span className="flex text-[15px] w-fit flex-wrap">
          Démarrez maintenant et obtenez votre financement
        </span>
      </div>
      <a
        href="dashboard/projects/new"
        className="flex text-center justify-center py-3 text-primary rounded-md bg-secondary hover:bg-hoverSecondary active:bg-activeSecondary"
      >
        <span className="flex text-[15px] font-bold">
          J’ajoute mon projet
        </span>
      </a>
    </div>
  );
};

export default AddProject;
