import React from "react";

const HelpCard = () => {
  return (
    <div className="flex flex-col rounded-xl bg-primary text-white p-6 justify-between gap-4 select-none">
      <div className="flex flex-col gap-3">
        <span className="flex flex-col">
          <span className="flex text-[20px] font-semibold w-fit flex-wrap">
            Besoin d’aide
          </span>
          <span className="flex text-[20px] font-semibold w-fit flex-wrap">
            ou d’assistance?
          </span>
        </span>
        <span className="flex text-[15px] w-fit flex-wrap">
          Nous sommes à votre écoute...
        </span>
      </div>
      <a
        href="dashboard/projects/new"
        className="flex text-center justify-center py-3 text-primary rounded-md bg-white hover:bg-hoverGrey active:bg-activeGrey"
      >
        <span className="flex text-[15px] font-bold">Service client</span>
      </a>
    </div>
  );
};

export default HelpCard;
