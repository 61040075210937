import React from "react";
import LOGOICON from "../../assets/logoiconprimary.svg";
import { primaryColor } from "../../constants";

const LoadingPage = ({
  message = "chargement de donnees en cours...",
  percent = 0,
  error = false,
}) => {
  return (
    <div className="flex flex-1 h-full justify-center items-center">
      <div className="flex flex-col items-center gap-4">
        <div className="flex h-[70px] w-[70px]">
          <img
            src={LOGOICON}
            alt="icon"
            className="flex flex-1 object-contain"
          />
        </div>
        {!error ? (
          <svg
            className="animate-spin h-6 w-6 text-black/50"
            xmlns="http://www.w3.org/2000/svg"
            fill={"transparent"}
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke={primaryColor}
              strokeWidth="2"
            ></circle>
            <path
              className="opacity-75"
              fill={primaryColor}
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        ) : (
          <span className="flex text-md text-red-700">{message}</span>
        )}
        {!error && (
          <div className="flex flex-col items-center">
            <span className="flex text-md">Veuillez patienter un instant</span>
            <span className="flex text-md">{message}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoadingPage;
