import React from "react";
import ValidationIcon from "../../../../components/icons/validation";
import { primaryColor } from "../../../../constants";

const ValidatedProject = () => {
  return (
    <div className="flex flex-col h-[230px] aspect-[7/5] rounded-xl bg-activeGrey text-primary p-6 justify-between gap-4 select-none">
      <div className="flex flex-col gap-3 px-2">
        <span className="flex flex-col">
          <span className="flex text-[20px] font-semibold w-fit flex-wrap">
            Mes projets financés
          </span>
        </span>
      </div>
      <div className="flex flex-row justify-between items-end">
        <ValidationIcon color={primaryColor} />
        <a
          href="dashboard/projects?filter=validate"
          className="flex text-center justify-center p-3 px-5 h-fit text-primary rounded-md bg-tertiary hover:bg-hoverTertiary active:bg-activeTertiary"
        >
          <span className="flex text-[15px] font-bold">Voir tout</span>
        </a>
      </div>
    </div>
  );
};

export default ValidatedProject;
