import React from "react";
import { errorColor } from "../../reactions/errorReaction";

function TextInputSign({
  title = "",
  placeholder = "",
  type = "text",
  errorState = { state: false, msg: "" },
  onTextChange = function (value: string) {},
  disabled=false
}) {
  const [errorColorStatus, setErrorColorStatus] = React.useState(
    errorColor(errorState.state)
  );
  React.useEffect(() => {
    setErrorColorStatus(errorColor(errorState.state));
  }, [errorState]);
  return (
    <div className="flex flex-col w-full gap-1">
      {title && <p className="flex text-sm">{title}</p>}
      <input
        type={type}
        disabled={disabled}
        className={`flex w-full py-3 px-4 border rounded-md bg-grey text-gray-900 placeholder-gray-500 text-[16px] ${
          errorState.state ? "placeholder:text-red-700" : ""
        } ${errorColorStatus.focus.border} ${errorColorStatus.focus.outline} ${
          errorColorStatus.focus.ring
        } ${errorColorStatus.usage.border} ${errorColorStatus.usage.outline} ${
          errorColorStatus.usage.ring
        }`}
        placeholder={placeholder}
        onChange={function (t) {
          onTextChange(t.currentTarget.value);
        }}
      />
      {errorState.state && errorState.msg.length !== 0 && (
        <p
          className={`flex ml-5 text-[12px] font-normal ${errorColorStatus.usage.text}`}
        >
          {errorState.msg}
        </p>
      )}
    </div>
  );
}
//
export default TextInputSign;
