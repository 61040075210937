import React from "react";

const SearchInTable = ({
  title = "Rechercher dans la liste",
  placeholder = "Entrer le nom de l'entite",
  onChange = (text: string) => {},
}) => {
  return (
    <div className="flex flex-col gap-3">
      <span className="flex text-[19px]">{title}</span>
      <div className="flex items-center flex-row gap-3 rounded-md py-3 px-3 bg-grey border-1 border-darkgrey w-[450px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5 stroke-darkgrey/50"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
          />
        </svg>
        <input
          className="flex flex-1 h-full outline-none bg-transparent"
          placeholder={placeholder}
          onChange={(e) => {
            onChange(e.currentTarget?.value ?? "");
          }}
        />
      </div>
    </div>
  );
};

export default SearchInTable;
