import React from "react";
import InfoBloc from "./infobloc";
import SignUpForm from "./form";

const SignUpPage = () => {
  return (
    <div className="flex flex-1 flex-row h-screen">
      <InfoBloc />
      <SignUpForm />
    </div>
  );
};

export default SignUpPage;
