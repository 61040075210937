import { Outlet } from "react-router-dom";
import Header from "../../components/header";
import useLayoutDimension from "../../functions/useLayoutDimension";
import Sidebar from "../../components/sidebar";

const DashboardPage = () => {
  const { height } = useLayoutDimension("#DashboardPageContentWrapper");
  return (
    <div className="flex flex-1 h-full flex-col">
      <Header />
      <div className="flex flex-1 flex-row gap-x-1">
        <Sidebar />
        <div className="flex flex-1" id="DashboardPageContentWrapper">
          <div
            className="flex flex-1 flex-col overflow-y-scroll py-7 pb-16 px-7"
            style={{
              maxHeight: height,
            }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
