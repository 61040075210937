import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import LoadingPage from "../pages/loading";
import { useAuth } from "../hooks/useAuth";

const PrivateRoute = ({ children = <div /> }) => {
  const { isAuthenticated, tokensDatasLoading } = useAuth();
  const location = useLocation();

  // Vérification de la validité du jeton d'accès
  // Utilisez la bibliothèque jwt-decode pour décoder le jeton d'accès
  // Vérifiez la date d'expiration et d'autres informations si nécessaire

  if (tokensDatasLoading) {
    return <LoadingPage message="" />;
  } else if (isAuthenticated) {
    return children;
  } else if (!isAuthenticated) {
    return <Navigate to={"/login"} state={{ from: location }} replace />;
  }
  return <div />;
};

{
  /* <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
                  <Route path="/" element={<Home />} />
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Editor]} />}>
                  <Route path="editor" element={<Editor />} />
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                  <Route path="admin" element={<Admin />} />
                </Route>

                <Route
                  element={<RequireAuth allowedRoles={[ROLES.Editor, ROLES.Admin]} />}
                >
                  <Route path="lounge" element={<Lounge />} />
                </Route> */
}

export default PrivateRoute;
