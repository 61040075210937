import MissingPage from "../../../pages/missing";
import LoadingPage from "../../../pages/loading";
import DashWelcome from "../../../pages/dashboard/pages/welcome";
import SettingIcon from "./icons/settings";
import DashProjects from "../../../pages/dashboard/pages/projects";
import DashFinance from "../../../pages/dashboard/pages/finance";
import DashBillings from "../../../pages/dashboard/pages/billings";
import DashAccounts from "../../../pages/dashboard/pages/compte";
import DashMessages from "../../../pages/dashboard/pages/messages";
import DashSettings from "../../../pages/dashboard/pages/settings";

export interface SubRouteModel {
  index?: boolean;
  path?: string;
  name?: string;
  icon?: JSX.Element | string;
  element?: JSX.Element;
  specify?: string;
  showSidebar?: boolean;
  showInSidebar?: boolean;
  isGenerativeRoute?: boolean;
  authorizedRoles?: string[];
  children?: Array<SubRouteModel>;
}

export const AuthRequireRoutes: Array<SubRouteModel> = [
  {
    path: "",
    name: "Tableau de bord",
    showSidebar: true,
    showInSidebar: true,
    authorizedRoles: ["*"],
    element: undefined,
    children: [
      {
        path: "",
        name: "any",
        showSidebar: true,
        showInSidebar: false,
        authorizedRoles: ["*"],
        element: <DashWelcome />,
      },
    ],
  },
  {
    path: "projects",
    name: "Projets",
    showSidebar: true,
    showInSidebar: true,
    authorizedRoles: ["*"],
    element: undefined,
    children: [
      {
        path: "",
        name: "any",
        showSidebar: true,
        showInSidebar: false,
        authorizedRoles: ["*"],
        element: <DashProjects />,
      },
    ],
  },
  {
    path: "finance",
    name: "Financements",
    showSidebar: true,
    showInSidebar: true,
    authorizedRoles: ["*"],
    element: undefined,
    children: [
      {
        path: "",
        name: "any",
        showSidebar: true,
        showInSidebar: false,
        authorizedRoles: ["*"],
        element: <DashFinance />,
      },
    ],
  },
  {
    path: "billing",
    name: "Paiements",
    showSidebar: true,
    showInSidebar: true,
    authorizedRoles: ["*"],
    element: undefined,
    children: [
      {
        path: "",
        name: "any",
        showSidebar: true,
        showInSidebar: false,
        authorizedRoles: ["*"],
        element: <DashBillings />,
      },
    ],
  },
  {
    path: "account",
    name: "Compte",
    showSidebar: true,
    showInSidebar: true,
    authorizedRoles: ["*"],
    element: undefined,
    children: [
      {
        path: "",
        name: "any",
        showSidebar: true,
        showInSidebar: false,
        authorizedRoles: ["*"],
        element: <DashAccounts />,
      },
    ],
  },
  {
    path: "message",
    name: "Messages",
    showSidebar: true,
    showInSidebar: true,
    authorizedRoles: ["*"],
    element: undefined,
    children: [
      {
        path: "",
        name: "any",
        showSidebar: true,
        showInSidebar: false,
        authorizedRoles: ["*"],
        element: <DashMessages />,
      },
    ],
  },
  {
    path: "settings",
    name: "Parametres",
    icon: <SettingIcon />,
    showSidebar: true,
    showInSidebar: true,
    authorizedRoles: ["*"],
    element: undefined,
    specify: "sidebar-bottom",
    children: [
      {
        path: "",
        name: "any",
        showSidebar: true,
        showInSidebar: false,
        authorizedRoles: ["*"],
        element: <DashSettings />,
      },
    ],
  },
  {
    path: "*",
    showSidebar: false,
    showInSidebar: false,
    authorizedRoles: ["*"],
    element: (
      <MissingPage>
        <div className="flex flex-col">
          <a
            href="/dashboard"
            className="flex mt-5 px-3 py-2 rounded-md bg-white shadow-sm"
          >
            Retourner au Tableau de bord
          </a>
        </div>
      </MissingPage>
    ),
  },
];

export const DefaultSubRoutes: Array<SubRouteModel> = [
  {
    path: "",
    showSidebar: false,
    showInSidebar: false,
    authorizedRoles: ["*"],
    element: <LoadingPage message="Quelques secondes s'il vous plait..." />,
  },
  {
    path: "*",
    showSidebar: false,
    showInSidebar: false,
    authorizedRoles: ["*"],
    element: (
      <MissingPage>
        <div className="flex flex-col">
          <button className="flex mt-5 px-3 py-2 rounded-md bg-white shadow-sm">
            Retourner au Tableau de bord
          </button>
        </div>
      </MissingPage>
    ),
  },
];
