import React from "react";
import { NavLink } from "react-router-dom";

const AccountGoProfile = ({ close = () => {} }) => {
  const [isActived, setisActived] = React.useState(false);
  return (
    <NavLink
      to={"account"}
      style={({ isActive, isPending }) => {
        if (isActive !== isActived) {
          setisActived(isActive);
        }
        return {};
      }}
      onClick={() => {
        close();
      }}
    >
      <div className="group flex px-[10px] py-[6px] hover:bg-darkgrey/10 rounded-[5px]">
        <span className="flex group-hover:text-primary">Mon profile</span>
      </div>
    </NavLink>
  );
};

export default AccountGoProfile;
