import React from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchInTable from "../../../../components/searchInput/searchInTable";

const ProjectsArray = () => {
    const [searchInput, setsearchInput] = React.useState("")
  return (
    <div className="flex">
      <div className="flex flex-row flex-wrap items-end gap-4 text-[17px] mb-4">
        <SearchInTable
          placeholder="Entrer le nom du projet rechercher..."
          onChange={setsearchInput}
        />
        <button className="flex px-4 py-[10px] gap-2 bg-grey hover:bg-hoverGrey active:bg-activeGrey rounded-md text-black">
          <FilterListIcon />
          <span className="flex">Filtrer la liste</span>
        </button>
        <button
          className="flex px-5 py-[10px] bg-primary hover:bg-hoverPrimary active:bg-activePrimary rounded-md text-white"
        //   onClick={navigateToNewRole}
        >
          <span className="flex">Ajouter un projet</span>
        </button>
      </div>
    </div>
  );
};

export default ProjectsArray;
