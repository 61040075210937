import { Outlet, Route } from "react-router-dom";
import { SubRouteModel } from "../../contexts/authbasics/defaults/subroutes";

function GenerateSubRoutes(routes = Array<SubRouteModel>()) {
  return routes.map((route, id) => {
    return (
      <Route key={id} path={route.path} element={route.element ?? <Outlet />}>
        {route.children && GenerateSubRoutes(route.children)}
      </Route>
    );
  });
}

export default GenerateSubRoutes;
