import React from "react";

type Props = {
  message?: string;
  confirm: () => void | Promise<any>;
  close: () => void;
  closeOnFinsh?: boolean;
};

const ConfirmActionModal = ({
  confirm,
  message = "evenement a confirmer ",
  close,
  closeOnFinsh = true,
}: Props) => {
  const [isLoading, setisLoading] = React.useState(false);
  async function onConfirm() {
    setisLoading(true);
    await confirm();
    if (closeOnFinsh) {
      close();
    } else {
      setisLoading(false);
    }
  }
  return (
    <div className="flex flex-col w-[300px] p-1 pt-0 gap-4 items-center justify-center">
      <span className="flex text-[20px] font-medium text-darkgrey">
        Confirmation
      </span>
      {message && (
        <span className="flex text-center text-[17px] font-normal text-darkgrey/95">
          {message}
        </span>
      )}
      <div className="flex flex-row gap-[10px] w-full">
        <button
          className={`flex flex-1 py-[8px] text-darkgrey ${
            isLoading ? "bg-primary" : "bg-hoverGrey"
          }  hover:bg-hoverPrimary active:bg-activePrimary hover:text-white rounded-md items-center justify-center`}
          onClick={
            !isLoading
              ? async () => {
                  await onConfirm();
                }
              : undefined
          }
        >
          {!isLoading ? (
            <span>Confirmer</span>
          ) : (
            <svg
              className="animate-spin h-5 w-5 text-black/50"
              xmlns="http://www.w3.org/2000/svg"
              fill={"transparent"}
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke={"white"}
                strokeWidth="2"
              ></circle>
              <path
                className="opacity-75"
                fill={"white"}
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
        </button>
        <button
          disabled={isLoading}
          className="flex flex-1 py-[8px] bg-grey hover:bg-hoverGrey active:bg-activeGrey rounded-md items-center justify-center"
          onClick={close}
        >
          <span>Annuler</span>
        </button>
      </div>
    </div>
  );
};

export default ConfirmActionModal;
