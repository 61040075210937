import React from 'react'
import PageDescription from '../../../../components/descriptionCard/pageDescription'

const DashAccounts = () => {
  return (
    <div className="flex h-auto flex-col gap-6">
      <span className="flex text-[30px] font-medium text-darkgrey">
        Compte
      </span>
      <PageDescription
        title="Description de la page"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit,
         sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
           nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
             pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
              culpa qui officia deserunt mollit anim id est laborum."
      />
    </div>
  )
}

export default DashAccounts