export function errorColor(error = false, data = {}) {
    const result = {
      usage: {
        border: error ? "border-1 border-red-700" : "",
        outline: error ? "outline-1 outline-red-700" : "",
        ring: error ? "ring-1 ring-red-700" : "",
        text: error ? "text-red-700" : "",
      },
      focus: {
        border: error ? "focus:border-red-700" : "focus:border-primary",
        outline: error ? "focus:outline-red-700" : "focus:outline-primary",
        ring: error ? "focus:ring-red-700" : "focus:ring-primary",
        text: error ? "focus:text-red-700" : "focus:text-primary",
      },
    };
    return { ...result, ...data };
  }
  