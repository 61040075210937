import { getTokens } from "../contexts/auth/functions";
import { api } from "./api";
import { decrypt, encrypt } from "./encryption";

export function tokenRefac(config: any) {
  let refreshToken = "";
  let token = "";
  if (!!config?.refreshToken) {
    refreshToken = encrypt(
      config.refreshToken,
      process.env.REACT_APP_Key,
      process.env.REACT_APP_Iv
    );
  }
  if (!!config?.token) {
    token = encrypt(
      config.token,
      process.env.REACT_APP_Key,
      process.env.REACT_APP_Iv
    );
  }
  return { refreshToken, token };
}

export async function getUrl(
  url: string,
  config: any = {},
  cb = function (reponse: any) {}
): Promise<{
  success: Boolean;
  message: String;
  data: any;
  [x: string]: any;
}> {
  const tokens = await getTokens();
  const configuration = {
    headers: {
      ...config.headers,
      "Content-type": "application/json",
      "x-refresh-token": `Bearer ${tokens.refreshToken}`,
      Authorization: `Bearer ${tokens.token}`,
    },
    ...config,
  };
  const reponse = await api.get(url, configuration);
  let encrypted = "";
  if (!!!reponse?.data) {
    return { success: false, message: "Requete invalide !", data: null };
  }
  encrypted = reponse.data.encTxt;
  const result = decrypt(
    encrypted,
    process.env.REACT_APP_Key,
    process.env.REACT_APP_Iv
  );
  cb(result);
  return result;
}

export async function deleteUrl(
  url: string,
  config: any = {},
  cb = function (reponse: any) {}
): Promise<{
  success: Boolean;
  message: String;
  data: any;
  [x: string]: any;
}> {
  const tokens = await getTokens();
  const configuration = {
    headers: {
      ...config.headers,
      "Content-type": "application/json",
      "x-refresh-token": `Bearer ${tokens.refreshToken}`,
      Authorization: `Bearer ${tokens.token}`,
    },
    ...config,
  };
  const reponse = await api.delete(url, configuration);
  let encrypted = "";
  if (!!!reponse.data) {
    return { success: false, message: "Requete invalide !", data: null };
  }
  encrypted = reponse.data.encTxt;
  const result = decrypt(
    encrypted,
    process.env.REACT_APP_Key,
    process.env.REACT_APP_Iv
  );
  cb(result);
  return result;
}

export async function postUrl(
  url: string,
  body = {},
  config: any = {},
  cb = function (reponse: any) {}
): Promise<{
  success: Boolean;
  message: String;
  data: any;
  [x: string]: any;
}> {
  const tokens = await getTokens();
  const encryptedbody = encrypt(
    body,
    process.env.REACT_APP_Key,
    process.env.REACT_APP_Iv
  );
  const configuration = {
    headers: {
      ...config.headers,
      "Content-type": "application/json",
      "x-refresh-token": `Bearer ${tokens.refreshToken}`,
      Authorization: `Bearer ${tokens.token}`,
      encryptedbody,
    },
    ...config,
  };
  const reponse = await api.post(url, {}, configuration);
  let encrypted = "";

  if (!!!reponse.data) {
    return { success: false, message: "Requete invalide !", data: null };
  }
  encrypted = reponse.data.encTxt;
  const result = decrypt(
    encrypted,
    process.env.REACT_APP_Key,
    process.env.REACT_APP_Iv
  );
  cb(result);
  return result;
}

export const request = {
  getUrl,
  deleteUrl,
  postUrl,
};
