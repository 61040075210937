import React from "react";
import { NavLink } from "react-router-dom";
import { DefaultSubRoutes } from "../../contexts/authbasics/defaults/subroutes";

const NavBarItem = ({ item = DefaultSubRoutes[0], onClick = () => {} }) => {
  const [isActived, setisActived] = React.useState(false);
  return (
    <NavLink
      style={({ isActive, isPending }) => {
        if (isActive !== isActived) {
          setisActived(isActive);
        }
        return {};
      }}
      to={item.path??""}
      end={item.path === ""}
      onClick={onClick}
      className={`group flex text-primary flex-col text-[17px] pl-10 pr-[10px] py-3 transition-all ${
        isActived
          ? "bg-secondary duration-0 font-bold"
          : "hover:bg-activeGrey duration-100"
      }`}
    >
      <div className="flex flex-row items-center gap-3">
        {item.icon && item.icon}
        <div className="flex">{item.name}</div>
      </div>
    </NavLink>
  );
};

export default NavBarItem;
