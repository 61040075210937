import React from "react";
import ModalCustom from "../../modals/modal";
import ConfirmActionModal from "../../modals/models/confirmAction";
import { useAuth } from "../../../hooks/useAuth";

const AccountLogOut = () => {
  const { logout } = useAuth();
  const [isOpen, setisOpen] = React.useState(false);
  function closeModal() {
    setisOpen(false);
  }

  async function confirmAction() {
    return logout();
  }

  return (
    <React.Fragment>
      <button
        className="group flex px-[10px] py-[6px] bg-grey/30 hover:bg-red-700/10 rounded-[4px]"
        onClick={() => {
          setisOpen(true);
        }}
      >
        <span className="flex text-red-600">Deconnexion</span>
      </button>
      <ModalCustom open={isOpen} handleClose={closeModal}>
        <ConfirmActionModal
          confirm={confirmAction}
          message="Voulez-vous vraiment vous deconnectez maintenant ?"
          close={closeModal}
        />
      </ModalCustom>
    </React.Fragment>
  );
};

export default AccountLogOut;
