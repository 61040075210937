import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import {
  PersistedClient,
  persistQueryClient,
} from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { compress, decompress } from "lz-string";
import { decrypt, encrypt } from "./configs/encryption";
import BasicsProvider from "./contexts/basics/provider";
import AuthProvider from "./contexts/auth/provider";
import StartRoutingApp from "./App";
import { NavigationLinkManager } from "./components/buttons/navigationLinkManager";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { primaryColor, secondaryColor } from "./constants";

const themeMui = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
  shape: {
    borderRadius: 6,
  },
});

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: Infinity } },
});

persistQueryClient({
  queryClient: queryClient,
  persister: createSyncStoragePersister({
    storage: window.localStorage,
    serialize: (data) =>
      compress(
        JSON.stringify({
          data: encrypt(
            JSON.stringify(data),
            process.env.REACT_APP_Key,
            process.env.REACT_APP_Iv
          ),
        })
      ),
    deserialize: (data) =>
      JSON.parse(
        decrypt(
          JSON.parse(decompress(data)).data,
          process.env.REACT_APP_Key,
          process.env.REACT_APP_Iv
        )
      ) as PersistedClient,
  }),
  maxAge: Infinity,
});

function Index() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={themeMui}>
            <BasicsProvider>
              <AuthProvider>
                <StartRoutingApp />
              </AuthProvider>
            </BasicsProvider>
            <NavigationLinkManager />
          </ThemeProvider>
          <ToastContainer />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

const rootElement = document.getElementById("root") as HTMLElement;
ReactDOM.createRoot(rootElement).render(<Index />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
