import React from "react";
import { Popover } from "@mui/material";
import AccountPopup from "./popup";
import { useAuthBasics } from "../../../hooks/useAuthBasics";

const AccoutHead = () => {
  const { user } = useAuthBasics();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleAnchor = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="group flex flex-row h-full items-center gap-x-4 select-none">
      <div className="flex flex-col justify-center gap-[2px] leading-tight h-[70%]">
        <span className="flex text-[12px] text-white/70">Bienvenue</span>
        <span className="flex text-[15px] font-medium">
          {user.user_name +
            " " +
            (user.user_surname ?? "").split(" ").slice(0, 2).join(" ")}
        </span>
      </div>
      <div
        className="flex h-[64%] justify-center rounded-full aspect-square bg-grey relative cursor-pointer"
        onClick={handleAnchor}
      >
        <div className="flex absolute bottom-[2px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="white"
            strokeWidth={3}
            className="hidden w-4 h-4 group-hover:flex group-hover:animate-bounce"
          >
            <path
              fillRule="evenodd"
              d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          marginTop: 2,
          padding: 0,
        }}
        elevation={3}
      >
        <AccountPopup close={handleClose} />
      </Popover>
    </div>
  );
};

export default AccoutHead;
