import React from "react";
import TextInputSign from "../../components/inputs/textInputsign";
import PasswordInputSign from "../../components/inputs/passwordInput";
import { signup } from "../../contexts/auth/functions";
import { navigateLink } from "../../components/buttons/navigationLinkManager";
import {
  ToastErrorNotifier,
  ToastSuccessNotifier,
  isPassword,
} from "../../constants";
import validator from "validator";
import { useTokenManager } from "../../hooks/useTokenManager";
import { tokenRefac } from "../../configs/apiRequest";
import { useQueryClient } from "@tanstack/react-query";

const SignUpForm = () => {
  const queryclient = useQueryClient();
  const { updateTokens } = useTokenManager();
  const [isLoading, setisLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    user_name: "",
    user_surname: "",
    user_email: "",
    user_password: "",
  });
  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...formData, [slug]: value }));
    };
  }
  function checkFormData() {
    let errorOccured = false;
    if (formData.user_name.length === 0) {
      errorOccured = true;
      ToastErrorNotifier({ message: "Nom invalide" });
    }
    if (formData.user_surname.length === 0) {
      errorOccured = true;
      ToastErrorNotifier({ message: "Prenom invalide" });
    }
    if (
      formData.user_email.length === 0 &&
      !validator.isEmail(formData.user_email)
    ) {
      errorOccured = true;
      ToastErrorNotifier({ message: "Email invalide" });
    }
    if (
      formData.user_password.length === 0 &&
      !isPassword(formData.user_password)
    ) {
      errorOccured = true;
      ToastErrorNotifier({
        message:
          "Mot de passe invalide; Doit contenir minimum: 1 majuscule, 1 minuscule, 1 chiffre",
      });
    }
    return errorOccured;
  }
  function submitForm(e: any) {
    e?.preventDefault();
    if (checkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    let data = {
      user_name: formData.user_name,
      user_surname: formData.user_surname,
      user_email: formData.user_email,
      user_password: formData.user_password,
    };
    setisLoading(true);
    signup(data).then((reponse) => {
      setisLoading(false);
      if (reponse.success) {
        ToastSuccessNotifier({
          message: "Inscription reussie !",
          duration: 710,
        });
        updateTokens({
          ...tokenRefac({
            token: reponse.token,
            refreshToken: reponse.refreshToken,
          }),
        }).then(() => {
          queryclient.invalidateQueries(undefined);
          setTimeout(() => {
            navigateLink("/");
          }, 700);
        });
      } else {
        ToastErrorNotifier({ message: reponse.message.toString() });
      }
    });
  }

  return (
    <div className="flex flex-1 flex-col items-center justify-center h-screen">
      <form
        onSubmit={submitForm}
        className="flex flex-col p-5 gap-6 bg-white w-[400px] overflow-y-auto py-4"
      >
        <div className="flex w-full justify-center pt-0 pb-1">
          <h2 className="flex text-center text-2xl font-bold">
            Créez votre compte
          </h2>
        </div>
        <TextInputSign
          type={"text"}
          placeholder="Nom"
          onTextChange={onValueChange("user_name")}
          disabled={isLoading}
        />
        <TextInputSign
          type={"text"}
          placeholder="Prénom"
          onTextChange={onValueChange("user_surname")}
          disabled={isLoading}
        />
        <TextInputSign
          type={"email"}
          placeholder="Adresse email"
          onTextChange={onValueChange("user_email")}
          disabled={isLoading}
        />
        <PasswordInputSign
          placeholder="Mot de passe"
          title="Mot de passe"
          onTextChange={onValueChange("user_password")}
          disabled={isLoading}
        />
        <button
          disabled={isLoading}
          onClick={submitForm}
          className="flex w-[99%] transition-all self-center justify-center items-center py-[15px] text-white text-[16px] font-medium bg-primary hover:bg-hoverPrimary active:bg-activePrimary rounded-md border"
        >
          <span className="flex">Inscription{isLoading && "..."}</span>
        </button>
        <a
          className="flex w-[99%] flex-col transition-all self-center justify-center border-2 border-primary text-primary items-center py-3 text-[16px] font-medium hover:bg-hoverGrey rounded-md"
          href={"/login"}
        >
          <p className="flex">Avez-vous deja un compte ?</p>
          <p className="flex">Si oui connectez-vous !</p>
        </a>
      </form>
    </div>
  );
};

export default SignUpForm;
