import { getUrl, postUrl } from "../../configs/apiRequest";
import { generateUniqueName } from "../../functions/generateUniqueName";

export function getTokens(): Promise<{ token: string; refreshToken: string }> {
  return new Promise<any>((resolve, reject) => {
    setTimeout(() => {
      resolve({
        token: localStorage.getItem(generateUniqueName("token")) ?? "",
        refreshToken:
          localStorage.getItem(generateUniqueName("refreshToken")) ?? "",
      });
    }, 700);
  });
}

export function setTokens({ token = "", refreshToken = "" }): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    localStorage.setItem(generateUniqueName("token"), token);
    localStorage.setItem(generateUniqueName("refreshToken"), refreshToken);
    setTimeout(() => {
      resolve({});
    }, 10);
  });
}

export function login(credentials = { login: "", password: "" }) {
  return postUrl("/user/auth/signin", credentials);
}

export function signup(data: any) {
  return postUrl("/user/auth/signup", data);
}

export function getUserData() {
  return getUrl("/user/auth/me");
}
