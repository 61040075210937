import React from "react";
import Logo from "../../assets/logoprimarywhite.svg";
import AccoutHead from "./account";
const Header = () => {
  return (
    <div className="flex flex-row items-center min-h-[65px] max-h-[75px] h-[10%] bg-primary text-white shadow-sm px-10">
      <a className="flex h-[60%]" href="/">
        <img className="flex h-full" src={Logo} alt="Logo" />
      </a>
      <div className="flex flex-1"></div>
      <AccoutHead />
    </div>
  );
};

export default Header;
