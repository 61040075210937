import React from "react";
import LoadingPage from "../../pages/loading";
import { AuthRequireRoutes, SubRouteModel } from "./defaults/subroutes";
import { UserModel } from "../../models/models";
import { useUser } from "../../hooks/useUser";

export const AuthBasicsContext = React.createContext({
  routes: Array<SubRouteModel>(),
  user: {} as UserModel,
});

const AuthBasicsProvider = ({ children = <div /> }) => {
  const [routes] = React.useState<Array<SubRouteModel>>(AuthRequireRoutes);
  const {user,isLoading:isLoadingUser} = useUser();
  const isLoading = isLoadingUser;

  return (
    <AuthBasicsContext.Provider value={{ routes, user }}>
      {!isLoading && <div className="flex flex-1">{children}</div>}
      {isLoading && (
        <div className="flex flex-1">
          <LoadingPage message={"Chargement des donnees en cours.."} />
        </div>
      )}
    </AuthBasicsContext.Provider>
  );
};

export default AuthBasicsProvider;
