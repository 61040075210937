import React from "react";
import { useLogin } from "../../hooks/useLogin";
import { setTokens } from "./functions";
import { navigateLink } from "../../components/buttons/navigationLinkManager";
import { useQueryClient } from "@tanstack/react-query";
import AuthBasicsProvider from "../authbasics/provider";

export const AuthContext = React.createContext({
  isAuthenticated: false,
  isLoadingLogin: false,
  isAuthenticating: false,
  tokensDatasLoading: false,
  tokensDatas: { token: "", refreshToken: "" },
  updateTokens: (tokens: { token: ""; refreshToken: "" }) => {},
  login: (credentials: { login: string; password: string }) => {},
  logout: async () => {},
  loginResponse: { success: false, message: "" },
});

const AuthProvider = ({ children = <div /> }) => {
  const query = useQueryClient();
  const {
    login,
    loginResponse,
    isLoading: isLoadingLogin,
    updateTokens,
    tokensDatas,
    tokensDatasLoading,
  } = useLogin();
  let isAuthenticated = tokensDatas.token.length !== 0;
  async function logout() {
    return setTokens({ token: "", refreshToken: "" }).then(async (e) => {
      query.clear();
      query.resetQueries(undefined);
      query.removeQueries(undefined);
      localStorage.clear();
      navigateLink("/");
      return e;
    });
  }
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoadingLogin,
        tokensDatasLoading,
        isAuthenticating: isLoadingLogin,
        tokensDatas,
        updateTokens,
        login,
        logout,
        loginResponse,
      }}
    >
      {!isAuthenticated && <div className="flex flex-1">{children}</div>}
      {isAuthenticated && (
        <AuthBasicsProvider>
          <div className="flex flex-1">{children}</div>
        </AuthBasicsProvider>
      )}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
