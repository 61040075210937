import { useMutation, useQueryClient } from "@tanstack/react-query";
import { login } from "../contexts/auth/functions";
import { useTokenManager } from "./useTokenManager";
import { tokenRefac } from "../configs/apiRequest";
import React from "react";
import { toast } from "react-toastify";
import { navigateLink } from "../components/buttons/navigationLinkManager";

export function useLogin() {
  const queryclient = useQueryClient();
  const { isLoading, tokensDatas, updateTokens } = useTokenManager();
  const [reponse, setReponse] = React.useState({ success: false, message: "" });
  const loginMutation = useMutation({
    mutationFn: login,
    onSuccess: ({ success, message, token = "", refreshToken = "" }) => {
      setReponse({ success: !!success, message: message + "" });
      if (success) {
        updateTokens({ ...tokenRefac({ token, refreshToken }) }).then(() => {
          queryclient.invalidateQueries(undefined);
          setTimeout(() => {
            navigateLink("/");
          }, 1550);
        });
      } else {
        toast.error(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    },
  });
  function loginU(credentials: { login: string; password: string }) {
    loginMutation.mutate(credentials);
  }
  return {
    login: loginU,
    isLoading: loginMutation.isLoading,
    loginResponse: reponse,
    tokensDatasLoading: isLoading,
    tokensDatas,
    updateTokens,
  };
}
