import React from "react";
import OutIcon from "../../contexts/authbasics/defaults/icons/out";
import ModalCustom from "../modals/modal";
import ConfirmActionModal from "../modals/models/confirmAction";
import { useAuth } from "../../hooks/useAuth";

const OutButtonNav = () => {
  const { logout } = useAuth();
  const [isOpen, setisOpen] = React.useState(false);
  function closeModal() {
    setisOpen(false);
  }

  async function confirmAction() {
    return logout();
  }
  return (
    <React.Fragment>
      <div
        onClick={() => setisOpen(true)}
        className={`group flex text-primary flex-col text-[17px] pl-10 pr-[10px] py-3 transition-all ${
          isOpen
            ? "bg-secondary duration-0 font-bold"
            : "hover:bg-activeGrey duration-100"
        }`}
      >
        <div className="flex flex-row items-center gap-3">
          <OutIcon />
          <div className="flex">Sortir</div>
        </div>
      </div>
      <ModalCustom open={isOpen} handleClose={closeModal}>
        <ConfirmActionModal
          confirm={confirmAction}
          message="Voulez-vous vraiment vous deconnectez maintenant ?"
          close={closeModal}
        />
      </ModalCustom>
    </React.Fragment>
  );
};

export default OutButtonNav;
